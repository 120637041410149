import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';

import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Typography from 'components/Main/Typography';
import API from 'apis/API';
import Routes from 'router/Routes';
import Progress from 'components/Main/Progress';

const useStyles = makeStyles((theme) => ({
	section: {
		background: theme.palette.white,
		paddingTop: theme.spacing(window.isMobile ? 1 : 2),
	},
	item: {
		alignItems: 'center',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	img: {
		width: 'fit-content',
		marginBottom: 35,
	},
	text: {
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},
	headTextMb: {
		marginBottom: 35,
	},
	registers: {
		color: 'inherit',
		backgroundColor: theme.palette.background.lightGray,
		padding: '20px 20px',
		minHeight: '150px',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.turquoise,
	},
	number: {
		display: 'inline-block',
		fontSize: 'inherit',
		color: theme.palette.turquoise,
	},
}));

const breadcrumbs = [{ title: 'Rejestry GMO i GMM', to: '' }];

const Registries = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();

	const [registries, setRegistries] = useState([]);

	useEffect(() => {
		API.registries.index({ limit: 0 }).then((res) => {
			setRegistries(res.data.data);
		}).finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <Progress status={true}/>;
	}

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<Header title="Rejestry GMO i GMM" className={classes.headTextMb} />

			<Box className={classes.section} mb={5}>
				<Typography style={{ marginBottom: 20 }}>
					Wybierz rejestr:
				</Typography>

				{registries.length > 0 && (
					<Grid container spacing={2} style={{ marginBottom: 20 }}>
						{registries?.map((registry) => (
							<Grid key={registry.id} item md={3} sm={6} xs={12} style={{ marginBottom: 20 }}>
								<Link to={Routes.Registries.Cards.List(registry.id)} className={classes.registers}>
									<Typography variant="subtitle2" style={{ fontWeight: 700 }} >
										<Typography component="span" className={classes.number}>[{registry.number}]</Typography> {registry.name}
									</Typography>
								</Link>
							</Grid>
						))}
					</Grid>
				)}
			</Box>
		</>
	);
};

export default Registries;
