import React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import InputTooltip from 'components/Main/InputTooltip';

const useStyles = makeStyles(theme => ({
	gutterBottom: {
		marginBottom: 10,
	},
	input: {
		'& .MuiInputBase-root': {
			background: theme.palette.white,
			borderRadius: 2,
			'& .MuiOutlinedInput-input': {
				padding: 10,
			},
			'& fieldset': {
				border: '1px solid #898989',
			},
			'&.Mui-disabled': {
				color: '#4D4D4D',
			},
			'&:not(.Mui-disabled)': {
				'&.Mui-focused, &:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: 1,
						borderColor: theme.palette.info.dark,
					},
				},
			},
			'&.MuiOutlinedInput-multiline': {
				padding: 0
			}
		},
		'& .MuiFormHelperText-root': {
			marginInline: 0
		}
	},
	dateInput: {
		background: theme.palette.white,
		'& .MuiInputBase-root': {
			borderRadius: 0,
			paddingRight: 0,
			'& .MuiOutlinedInput-input': {
				padding: 10,
				paddingRight: 0,
				marginRight: -8,
			},
			'& .MuiInputAdornment-root': {
				margin: 0,
				'& .MuiButtonBase-root': {
					padding: 10,
				},
			},
			'& fieldset': {
				border: '1px solid #898989',
			},
			'&:not(.Mui-disabled)': {
				'&.Mui-focused, &:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: 1,
						borderColor: theme.palette.info.dark,
					},
				},
			},
			'&.Mui-disabled': {
				color: '#4D4D4D',
			},
		},
	},
	boxInput: {
		background: theme.palette.white,
		padding: 9,
		border: '1px solid #bdbdbd',
		color: '#4D4D4D',
		'& p': {
			lineHeight: '1.1876em',
		}
	},
}));
export const BaseInput = props => {
	const {
		className,
		required = false,
		disabled = false,
		value = '',
		...rest
	} = props;
	const classes = useStyles();
	const workaround = required || disabled ? '' : '';

	return (
		<Box
			className={clsx(classes.input, className, classes.boxInput)}
			{...rest}><Typography>{(value + workaround).length ? value : ' '}</Typography></Box>
	);
};

export const FormInput = (props) => {
	const {
		title,
		titleVariant = 'bodyM',
		alignItems = 'center',
		containerClassName,
		inputClassName,
		gutterBottom = true,
		helperText,
		vertical = false,
		sm = 4,
		titleClassName,
		required = false,
		disabled = false,
		value = '',
		...rest
	} = props;
	const classes = useStyles();
	const workaround = required || disabled ? '' : '';

	return (
		<Grid
			container
			alignItems={alignItems}
			spacing={1}
			className={clsx(
				{ [classes.gutterBottom]: gutterBottom },
				containerClassName
			)}
		>
			{title && (
				<Grid item xs={12} sm={vertical ? 12 : sm}>
					<Box display="flex" alignItems="center">
						<Typography
							variant={titleVariant}
							component="p"
							className={titleClassName}
						>
							{title}
						</Typography>
						{helperText && <InputTooltip title={helperText} />}
					</Box>
				</Grid>
			)}
			<Grid item xs={12} sm={vertical || !title ? 12 : 12 - sm}>
				<Box
					className={clsx(inputClassName, classes.boxInput)}
					fullWidth
					{...rest}
				><Typography>{(value + workaround).length ? value : ' '}</Typography></Box>
			</Grid>
		</Grid>
	);
};

export const DateInput = props => {
	const {
		title,
		titleVariant = 'bodyM',
		containerClassName,
		className,
		gutterBottom = true,
		vertical = false,
		sm = 4,
		required = false,
		disabled = false,
		value = '',
		...rest
	} = props;
	const classes = useStyles();
	const momentObject = moment(value);
	const workaround = required || disabled ? ' ' : ' ';
	const dateValue = momentObject.isValid() ? momentObject.format('DD.MM.YYYY') : workaround;

	return (
		<>
			<Grid
				container
				alignItems="center"
				spacing={1}
				className={clsx({ [classes.gutterBottom]: gutterBottom }, containerClassName)}
			>
				{title &&
					<Grid item xs={12} sm={vertical ? 12 : sm}>
						<Box display="flex" alignItems="center">
							<Typography variant={titleVariant} component="p">
								{title}
							</Typography>
						</Box>
					</Grid>
				}
				<Grid item xs={12} sm={vertical || !title ? 12 : 12 - sm}>
					<Box
						className={clsx(classes.dateInput, className, classes.boxInput)}
						{...rest}
					><Typography>{dateValue}</Typography></Box>
				</Grid>
			</Grid>
		</>
	);
};
