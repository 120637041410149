import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from 'layouts/Main';
import Routes from './Routes';

import Home from 'pages/Home';

import Registries from 'pages/Registries';
import RegistryCards from 'pages/Cards';
import RegistryCardPreview from 'pages/Cards/Preview';

import Page from 'pages/Pages';

import Search from 'pages/Search';

const ComponentRoutes = [
	{
		component: Home,
		title: 'Strona główna',
		path: Routes.Home,
	},
	{
		component: Registries,
		title: 'Rejestry GMO i GMM',
		path: Routes.Registries.List,
	},
	{
		component: RegistryCards,
		title: 'Lista kart w rejestrze',
		path: Routes.Registries.Cards.List(),
	},
	{
		component: RegistryCardPreview,
		title: 'Podgląd karty',
		path: Routes.Registries.Cards.Preview(),
	},
	{
		component: Search,
		title: 'Wyszukiwarka',
		path: Routes.Search,
	},
];

const RoutesRouter = () => {
	return (
		<Switch>
			{ComponentRoutes.map((route, index) => {
				const { component, ...routeProps } = route;
				const RenderComponent = component;
				return <Route key={index} exact {...routeProps} render={props =>
					<Main>
						<RenderComponent {...props}/>
					</Main>
				}/>;
			})}

			{/*<Route path="*" component={NotFoundWithLayout} />*/}
			<Route path="*" render={props =>
				<Main>
					<Page {...props}/>
				</Main>
			}/>
		</Switch>
	);
};

export default RoutesRouter;
