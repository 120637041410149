import React, { useState, useContext, useCallback, useEffect } from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import _, { debounce } from 'lodash';
import clsx from 'clsx';

import Typography from 'components/Main/Typography';
import Select from 'components/Form/Select';
import FormInput from 'components/Form/FormInput';
import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import Checkbox from 'components/Form/Checkbox';
import { Expand, Collapse } from 'assets/svg';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Tooltip from 'components/Main/Tooltip';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import GlobalContext from 'context/GlobalContext';
import Progress from 'components/Main/Progress';

const useStyles = makeStyles((theme) => ({
	container: {
		background: theme.palette.background.lightGray,
		padding: 20,
		marginBottom: 50,
	},
	section: {
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		paddingRight: theme.spacing(9),
		paddingBottom: theme.spacing(4)
	},
	gutterBottom: {
		marginBottom: 10,
	},
	expandButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		fontWeight: 600,
		cursor: 'pointer',
		'& span': {
			marginRight: 10,
		},
	},
	selectContainer: {
		flexWrap: 'nowrap',
		justifyContent: 'flex-end',
		'& .MuiGrid-grid-md-12': {
			minWidth: 'max-content',
		},
		'& .MuiGrid-root': {
			flexBasis: 0,
		},
		'& .MuiFormControl-root': {
			width: 120,
		}
	},
	select: {
		textIndent: 6,
	},
	active: {
		outline: '1px solid ' + theme.palette.info.main,
	},
	checkbox: {
		'& .MuiFormControlLabel-root': {
			marginLeft: -4,
		}
	},
	number: {
		color: theme.palette.turquoise,
		top: 0,
		width: 40,
		bottom: 0,
		display: 'flex',
		position: 'absolute',
		justifyContent: 'flex-end',
		marginLeft: -80,
		alignItems: 'center',
	},
}));

const categoryOptions = [
	{ value: 'I', label: 'I' },
	{ value: 'II', label: 'II' },
	{ value: 'III', label: 'III' },
	{ value: 'IV', label: 'IV' },
];

const countOptions = [
	{ value: 5, label: '5' },
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' },
	{ value: 1000000, label: 'Wszystkie' },
];

const Filters = ({
	title = 'Wyszukaj',
	params,
	setParams,
	defaultFilters,
	registries,
	loading,
	submitHandler,
}) => {
	const { voivodeships } = useContext(GlobalContext);
	const classes = useStyles();
	const [isExpanded, setIsExpanded] = useState(true);
	const [filters, setFilters] = useState({...defaultFilters, ...params.filters});
	const paramsHandler = useCallback(debounce(setParams, 500), []);

	const filtersHandler = event => {
		const { name, value, checked, type } = event.target;
		setFilters(prev => _.set({ ...prev }, name, type === 'checkbox' ? checked : value));
	};

	const registryIdsHandler = registryId => event => {
		const { checked } = event.target;
		let ids = params.registryIds;
		if (checked) {
			ids.push(registryId);
		} else {
			_.pull(ids, registryId);
		}
		setParams(prev => ({ ...prev, registryIds: _.uniq(ids) }));
	};

	const toggleRegistryIds = () => {
		let registryIds = [];
		if (_.size(params.registryIds) !== _.size(registries)) {
			registryIds = _.uniq(_.keys(registries).map(v => _.toInteger(v)));
		}
		setParams(prev => ({ ...prev, registryIds }));
	};

	useEffect(() => {
		paramsHandler(prev => {
			const params = {
				...prev, filters: _.pickBy(filters, value => (
					_.isObjectLike(value) ? _.some(value, v => _.toString(v).length) : _.toString(value).length
				))
			};
			return _.isEqual(prev, params) ? prev : params;
		});
	}, [filters]);

	return (
		<Box className={classes.container}>
			<Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={isExpanded ? 3 : 0}>
				<Typography variant="h3">
					{title}
				</Typography>

				<Box onClick={() => setIsExpanded(!isExpanded)}>
					<Typography className={classes.expandButton}>
						{isExpanded ? (
							<>Zwiń filtry <Collapse style={{ marginLeft: 8 }}/></>
						) : (
							<>Rozwiń filtry <Expand style={{ marginLeft: 8 }}/></>
						)}
					</Typography>
				</Box>
			</Box>

			{isExpanded && (
				<Grid container>
					<Grid item lg={9} xs={12}>
						<Box className={classes.section}>

							<Grid container spacing={1} className={classes.gutterBottom}>
								<Grid item xs={12} md={6}>
									<FormInput
										vertical={true}
										title="Wyszukiwanie pełnotekstowe"
										name="phrase"
										value={filters['phrase']}
										onChange={filtersHandler}
										placeholder="Wpisz szukaną frazę"
										disabled={loading}
										inputClassName={clsx({ [classes.active]: !_.isEmpty(filters['phrase']) })}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Grid container spacing={1} className={classes.gutterBottom}>
										<Grid item xs={12}>
											<Box display="flex" alignItems="center">
												<Typography component="p">
													Numer karty
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Box display="flex" alignItems="center">
												<BaseInput
													name="card_nr.reg_nb"
													value={filters['card_nr.reg_nb']}
													onChange={filtersHandler}
													style={{ width: 60 }}
													inputProps={{ style: { textAlign: 'center' } }}
													placeholder="rejestr"
													disabled={loading}
													className={clsx({ [classes.active]: !_.isEmpty(filters['card_nr.reg_nb']) })}
												/>
												<Typography style={{ margin: '0 5px' }}>
													-
												</Typography>
												<BaseInput
													name="card_nr.card_nb"
													value={filters['card_nr.card_nb']}
													onChange={filtersHandler}
													style={{ width: 60 }}
													inputProps={{ style: { textAlign: 'center' } }}
													placeholder="karta"
													disabled={loading}
													className={clsx({ [classes.active]: !_.isEmpty(filters['card_nr.card_nb']) })}
												/>
												<Typography style={{ margin: '0 5px' }}>
													/
												</Typography>
												<BaseInput
													name="card_nr.year"
													value={filters['card_nr.year']}
													onChange={filtersHandler}
													style={{ width: 58 }}
													inputProps={{ style: { textAlign: 'center' } }}
													placeholder="rok"
													disabled={loading}
													className={clsx({ [classes.active]: !_.isEmpty(filters['card_nr.year']) })}
												/>
											</Box>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={3}>
									<Select
										vertical={true}
										title="Kategoria zagrożenia"
										options={categoryOptions}
										displayEmpty
										emptyLabel="Wybierz"
										name="threat_category"
										value={filters['threat_category']}
										onChange={filtersHandler}
										style={{ marginBottom: 5 }}
										disabled={loading}
										className={clsx({ [classes.active]: !_.isEmpty(filters['threat_category']) })}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={1} className={classes.gutterBottom}>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Box display="flex" alignItems="center">
												<Typography>
													Data złożenia wniosku
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12} md={6}>
											<DateInput
												fullWidth
												gutterBottom={false}
												name="submit_date.from"
												value={filters['submit_date']['from']}
												onChange={filtersHandler}
												placeholder="od"
												style={{ minWidth: 160 }}
												disabled={loading}
												className={clsx({ [classes.active]: !_.isEmpty(filters['submit_date']['from']) })}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<DateInput
												fullWidth
												gutterBottom={false}
												name="submit_date.to"
												value={filters['submit_date']['to']}
												onChange={filtersHandler}
												placeholder="do"
												style={{ minWidth: 160 }}
												disabled={loading}
												className={clsx({ [classes.active]: !_.isEmpty(filters['submit_date']['to']) })}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Box display="flex" alignItems="center">
												<Typography>
													Data wydania decyzji
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12} md={6}>
											<DateInput
												fullWidth
												gutterBottom={false}
												name="decision_date.from"
												value={filters['decision_date']['from']}
												onChange={filtersHandler}
												placeholder="od"
												style={{ minWidth: 160 }}
												disabled={loading}
												className={clsx({ [classes.active]: !_.isEmpty(filters['decision_date']['from']) })}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<DateInput
												fullWidth
												gutterBottom={false}
												name="decision_date.to"
												value={filters['decision_date']['to']}
												onChange={filtersHandler}
												placeholder="do"
												style={{ minWidth: 160 }}
												disabled={loading}
												className={clsx({ [classes.active]: !_.isEmpty(filters['decision_date']['to']) })}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<FormInput
								vertical={true}
								title="Nazwa wnioskodawcy"
								name="applicant.name"
								value={filters['applicant.name']}
								onChange={filtersHandler}
								placeholder="Wpisz szukaną frazę"
								disabled={loading}
								inputClassName={clsx({ [classes.active]: !_.isEmpty(filters['applicant.name']) })}
							/>
							<FormInput
								vertical={true}
								title="Nazwa przedstawiciela wnioskodawcy"
								name="representative.full_name"
								value={filters['representative.full_name']}
								onChange={filtersHandler}
								placeholder="Wpisz szukaną frazę"
								disabled={loading}
								inputClassName={clsx({ [classes.active]: !_.isEmpty(filters['representative.full_name']) })}
							/>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6}>
									<FormInput
										vertical={true}
										title="Miejscowość wnioskodawcy"
										name="applicant.city"
										value={filters['applicant.city']}
										onChange={filtersHandler}
										placeholder="Wpisz szukaną frazę"
										disabled={loading}
										inputClassName={clsx({ [classes.active]: !_.isEmpty(filters['applicant.city']) })}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Select
										vertical={true}
										title="Województwo wnioskodawcy"
										options={voivodeships}
										displayEmpty
										emptyLabel="Wybierz"
										name="applicant.voivodeship_id"
										value={filters['applicant.voivodeship_id']}
										onChange={filtersHandler}
										disabled={loading}
										className={clsx({ [classes.active]: !_.isEmpty('' + filters['applicant.voivodeship_id']) })}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								{/*<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
								<Grid item xs={6} md={5}>
									<ConditionalWrapper
										condition={!_.some(params.registryIds)}
										wrapper={children => <Tooltip title="Najpierw wybierz rejestr(y)">{children}</Tooltip>}
									>
										<Box display="inline">
											<PrimaryButton onClick={submitHandler} disabled={!_.some(params.registryIds) || loading}>
												{loading && <Progress status={true} size="1rem" style={{ marginRight: 10 }}/>}
												{title}
											</PrimaryButton>
										</Box>
									</ConditionalWrapper>
								</Grid>
								<Grid item xs={6} md={4}>
									{_.some(params.filters) && (
										<PrimaryButton onClick={() => setFilters(defaultFilters)} disabled={loading}>
											Wyczyść filtry
										</PrimaryButton>
									)}
								</Grid>
								<Grid item xs={12} md={3}>
									<Box>
										<Select
											titleNowrap
											vertical
											title="Ilość wyników na stronie:"
											options={countOptions}
											value={params.limit}
											onChange={e => setParams(prev => ({ ...prev, limit: e.target.value }))}
											containerClassName={classes.selectContainer}
											className={classes.select}
											gutterBottom={false}
											disabled={loading}
										/>
									</Box>
								</Grid>
								{/*</Grid>*/}
							</Grid>
						</Box>
					</Grid>
					<Grid item lg={3} xs={12}>
						<Box
							className={classes.section}
							style={{
								height: '100%',
								marginRight: 0,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								paddingRight: 0,
							}}
						>
							<Box>
								<Grid container alignItems="center">
									<React.Fragment key={0}>
										<Grid item xs={1}>
											<Checkbox
												containerClass={classes.checkbox}
												value={_.size(params.registryIds) === _.size(registries)}
												onChange={toggleRegistryIds}
												fullWidth
												color="primary"
												disabled={loading}
											/>
										</Grid>
										<Grid item xs={11}>
											<Typography variant="h3" style={{ marginLeft: 5 }}>
												Wybierz rejestry
											</Typography>
										</Grid>
									</React.Fragment>
									{_.values(registries).map((item) => (
										<React.Fragment key={item.id}>
											<Grid item xs={1}>
												<Checkbox
													containerClass={classes.checkbox}
													value={params.registryIds.includes(item.id) || false}
													onChange={registryIdsHandler(item.id)}
													fullWidth
													color="primary"
													disabled={loading}
												/>
											</Grid>
											<Grid item xs={11} style={{ position: 'relative' }}>
												<Typography className={classes.number}>
													[{item.number}]
												</Typography>
												<Typography style={{ marginLeft: 5 }}>
													{item.name}
												</Typography>
											</Grid>
										</React.Fragment>
									))}
								</Grid>
							</Box>
						</Box>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default Filters;
