import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Typography from 'components/Main/Typography';
import convertBytes from 'utils/convertBytes';
import { FormInput } from 'components/Form/BoxInputs';
import { DownloadIcon } from 'assets/svg';
import _ from 'lodash';

const Attachments = (props) => {
	const {
		schemaAttachments,
		cardId,
		cardData,
		setCardData,
		isPreview,
		classes,
	} = props;

	const initAttachments = () => {
		let cardAttachments = _.values(cardData?.attachments) || [];
		let _attachments = [];
		_.forEach(schemaAttachments, config => {
			if (config.visible) {
				const index = _.findIndex(cardAttachments, ['name', config.name]);
				_attachments.push(index < 0 ? { name: config.name } : _.pullAt(cardAttachments, index).pop());
			}
		});

		let attachments = _attachments.concat(cardAttachments);
		return attachments.filter(v => v.filename);
	};
	const [attachments] = useState(initAttachments());

	const downloadHandler = attachment => () => {
		const link = document.createElement('a');
		link.href = process.env.REACT_APP_API_ENDPOINT_URI + `/public/attachment/${cardId}/${attachment.uuid}`;
		link.setAttribute('download', attachment.filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	useEffect(() => {
		setCardData(prev => _.set({ ...prev }, 'attachments', attachments));
	}, [attachments]);

	let fileIndex = 0;

	return (
		<Box className={classes.section}>
			<Typography variant="h3" style={{ marginBottom: 20 }}>
				Załączniki
			</Typography>

			<Grid container spacing={1}>
				{!attachments.length && (
					<Grid item xs={12} className={classes.gutterBottom}>
						<Typography style={{color: '#777'}}>Brak załączników</Typography>
					</Grid>
				)}
				{attachments.map((attachment, index) => {
					const { name, filename, size, extension, custom_name /*, mime_type, uuid*/ } = attachment;
					const config = schemaAttachments[name] ?? null;
					if (!config || !name) return; // hide invalid
					if (attachment?.delete) return; // hide marked for deletion
					if (!attachment.public) return;
					if (isPreview && !filename) return;

					return (
						<React.Fragment key={index}>
							<Grid item xs={5} className={classes.gutterBottom}>
								<Grid container style={{ alignItems: 'baseline' }}>
									<Grid item xs={1}>
										<Typography style={{ marginRight: 10 }}>
											{++fileIndex}.
										</Typography>
									</Grid>
									<Grid item xs={11}>
										<FormInput
											multiline
											vertical
											value={config?.custom_name && custom_name ? custom_name : config.label}
											fullWidth
											disabled={isPreview}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={7} className={classes.gutterBottom}>
								<Box display="flex" alignItems="center" justifyContent="space-between">
									<Typography className={classes.filename} onClick={downloadHandler(attachment)}>
										<DownloadIcon/>
										{filename} ({convertBytes(size)}, {extension})
									</Typography>
								</Box>
							</Grid>
						</React.Fragment>
					);
				})}
			</Grid>
		</Box>
	);
};

export default Attachments;
