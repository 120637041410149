import React, {useState} from 'react';

import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import Select from 'components/Form/Select';
import ExportButton from 'components/Buttons/ExportButtons/ExportButton';

const useStyles = makeStyles(() => ({
	hidden: {
		display: 'none',
	},
	exportButtonLabel: {
		marginRight: 10,
		color: '#1D1D1B',
		fontWeight: 400,
	},
	select: {
		flexWrap: 'nowrap',
		'& .MuiGrid-grid-sm-6': {
			minWidth: 'max-content',
		},
	},
	loader: {
		color: '#ffffff',
		marginRight: 8
	}
}));

const countOptions = [
	{ value: 5, label: '5' },
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' },
];

const ExportButtons = (props) => {
	const {
		filename = 'export',
		title = 'Pobierz dane',
		endpoint,
		perPageCount,
		onChangeCount,
	} = props;
	const classes = useStyles();
	const [isExporting, setIsExporting] = useState(false);

	const download = format => () => {
		// prevent next export while another one is in progress
		if (isExporting) {
			return;
		}

		setIsExporting(format);
		endpoint({ responseType: 'blob', params: { export: format } }).then(res => {
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename + '.' + format);
			// Append to html link element page
			document.body.appendChild(link);
			// Start download
			link.click();
			// Clean up and remove the link
			link.parentNode.removeChild(link);

		}).finally(() => {
			setIsExporting(false);
		});
	};

	return (
		<Box
			display={window.isMobile ? 'block' : 'flex'}
			alignItems="center"
			justifyContent="space-between"
			mt={4}
		>
			<Box display="flex" alignItems="center">
				<Typography variant="bodyS" className={classes.exportButtonLabel}>
					{title}
				</Typography>

				<ExportButton onClick={download('xlsx')}>
					{isExporting === 'xlsx' && <CircularProgress className={classes.loader} size={12}/>} xlsx
				</ExportButton>
				<ExportButton onClick={download('csv')}>
					{isExporting === 'csv' && <CircularProgress className={classes.loader} size={12}/>} csv
				</ExportButton>
				<ExportButton onClick={download('pdf')}>
					{isExporting === 'pdf' && <CircularProgress className={classes.loader} size={12}/>} pdf
				</ExportButton>
			</Box>

			<Box display="flex" alignItems="center" aria-label={`${perPageCount} wyników na stronę`}>
				{onChangeCount && (
					<Select
						title="Ilość wyników na stronie:"
						options={countOptions}
						value={perPageCount}
						onChange={onChangeCount}
						containerClassName={classes.select}
						sm={6}
						gutterBottom={false}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ExportButtons;
