import React, { useEffect, useState, useCallback } from 'react';
import _, { debounce } from 'lodash';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const FilterRow = ({ setParams, defaultFilters, renderRow }) => {
	const paramsHandler = useCallback(debounce(setParams, 500), []);
	const [filters, setFilters] = useState(defaultFilters);
	const [didMount, setDidMount] = useState(false);

	const filterChangeHandler = event => {
		const { name, value } = event.target;
		setFilters(prev => _.set(_.cloneDeep(prev), name, value));
	};
	const clearFiltersButton = _.some(filters, value => (
		_.isObjectLike(value) ? _.some(value, v => _.toString(v).length) : _.toString(value).length
	)) && (
		<PrimaryButton onClick={() => setFilters(defaultFilters)} ariaLabel="Wyczyść wszystkie filtry">
			Wyczyść
		</PrimaryButton>
	);

	useEffect(() => {
		setDidMount(true);
	}, []);

	useEffect(() => {
		if (didMount) {
			paramsHandler(prev => {
				const params = {
					...prev, page: 1, filters: _.pickBy(filters, value => (
						_.isObjectLike(value) ? _.some(value, v => _.toString(v).length) : _.toString(value).length
					))
				};
				return _.isEqual(prev, params) ? prev : params; // _.isEqual() is important to avoid duplicate loading
			});
		}
	}, [filters]);

	return renderRow(filters, filterChangeHandler, clearFiltersButton);
};

export default FilterRow;
