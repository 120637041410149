import React, { useEffect, useState } from 'react';

import API from 'apis/API';
import RoutesRouter from 'router/RoutesRouter';
import { toast } from 'react-toastify';

const GlobalContext = React.createContext(null);

export const GlobalContextProvider = () => {
	const [interceptorInstalled, setInterceptorInstalled] = useState(false);
	const [voivodeships, setVoivodeships] = useState([]);
	const [titles, setTitles] = useState([]);

	useEffect(() => {
		API.interceptors.response.use(
			(res) => {
				return res;
			},
			(err) => {
				console.error(err.response);
				const error = err.response?.data?.data?.error;

				if (err.response?.status === 500) {
					toast.error(
						'Wystąpił nieoczekiwany błąd. Jeżeli będzie się powtarzał, prosimy powiadom nas o tym.'
					);
					return Promise.reject(err);
				}

				if (typeof error === 'string') {
					toast.error(error);
				} else if (Array.isArray(error)) {
					error.forEach((errMsg) => toast.error(errMsg));
				}

				return Promise.reject(err);
			}
		);

		setInterceptorInstalled(true);

		API.dict.all().then(res => {
			const data = res.data?.data;
			setVoivodeships(data?.voivodeships || []);
			setTitles(data?.titles || []);
		});
	}, []);

	return (
		<GlobalContext.Provider value={{ voivodeships, titles }}>
			{/* {!loading && */}
			{interceptorInstalled && <RoutesRouter />}
			{/* } */}
		</GlobalContext.Provider>
	);
};

export default GlobalContext;
