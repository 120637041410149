import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import ErrorBoundary from 'utils/ErrorBoundary';
import { useMediaQuery } from '@material-ui/core';

const Main = (props) => {
	const { children } = props;
	const location = useLocation();
	const [openSidebar, setOpenSidebar] = useState(false);

	const isMobile = useMediaQuery('(max-width:1150px)');

	const useStyles = makeStyles((theme) => ({
		root: {
			paddingTop: 130,
			marginBottom: 40
		},
		content: {
			position: 'relative',
			overflowX: 'hidden',
			overflowY: 'auto',
			padding: isMobile ? 20 : theme.spacing(0, 15, 7),
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		if (isMobile) {
			setOpenSidebar(false);
		}
	}, [location.pathname]);

	return (
		<div>
			<div
				id="page-container"
				className={clsx({
					[classes.root]: true,
				})}
			>
				<Topbar setOpenSidebar={setOpenSidebar} />
				{openSidebar && isMobile && (
					<Sidebar
						open={openSidebar}
						variant={'temporary'}
						setOpenSidebar={setOpenSidebar}
					/>
				)}
				<ErrorBoundary>
					<main aria-label="Treść" className={classes.content}>
						{children}
					</main>
				</ErrorBoundary>
			</div>
			<div className={classes.content}>
				<Footer />
			</div>
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node,
};

export default Main;
