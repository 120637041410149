const standarizeDate = (date) => {
	if (!date) {
		return date;
	}

	let splited = date.split('.');
	return splited.length === 3 ? splited[2]+'-'+splited[1]+'-'+splited[0] : date;
};

export default standarizeDate;
