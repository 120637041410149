import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from './Typography';
import { DangerIcon } from 'assets/svg';

const useStyles = makeStyles((theme) => ({
	error: {
		display: 'flex',
		padding: 12,
		backgroundColor: theme.palette.secondary.light,
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 2,
	},
	errorText: {
		marginLeft: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
}));

const FormError = (props) => {
	const { title, className, titleVariant = 'bodyL', errors = {} } = props;
	const classes = useStyles();
	
	return (
		<Box className={clsx(classes.error, className)}>
			<DangerIcon />

			<Box>
				<Typography
					variant={titleVariant}
					className={classes.errorText}
				>
					{title || 'W formularzu występują błędy'}
				</Typography>

				{errors &&
					<Box marginLeft={2}>
						{Object.values(errors)?.map((message) => (
							<Typography
								key={message}
								variant={titleVariant}
								className={classes.errorText}
							>
								- {message}
							</Typography>
						))}
					</Box>
				}
			</Box>
		</Box>
	);
};

FormError.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default FormError;
