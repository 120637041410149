import { Grid } from '@material-ui/core';
import { FormInput } from 'components/Form/BoxInputs';
import React, { useContext } from 'react';
import GlobalContext from 'context/GlobalContext';
import _ from 'lodash';

const ApplicantForm = ({ applicant, disabled, onChange, errors = {} }) => {
	const { voivodeships } = useContext(GlobalContext);

	return (
		<>
			<Grid item xs={12}>
				<FormInput
					vertical={true}
					title="Nazwa wnioskodawcy"
					name="name"
					value={applicant?.name ?? ''}
					inputProps={{ 'aria-required': true }}
					required
					disabled={disabled}
					onChange={onChange}
					error={errors?.['applicant.name']}
				/>
			</Grid>

			<Grid container spacing={1}>
				<Grid item xs={12} sm={3}>
					<FormInput
						vertical={true}
						title="Województwo"
						name="voivodeship_id"
						value={_.find(voivodeships, ['value', applicant?.voivodeship_id])?.label ?? ''}
						required
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.voivodeship_id']}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormInput
						vertical={true}
						title="Kod pocztowy"
						name="zip"
						value={applicant?.zip ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.zip']}
					/>
				</Grid>
				<Grid item xs={12} sm={7}>
					<FormInput
						vertical={true}
						title="Miejscowość"
						name="city"
						value={applicant?.city ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.city']}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<FormInput
						vertical={true}
						title="Ulica"
						name="street"
						value={applicant?.street ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.street']}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<FormInput
						vertical={true}
						title="Nr budynku"
						name="house"
						value={applicant?.house ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.house']}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<FormInput
						vertical={true}
						title="Nr lokalu"
						name="locale"
						value={applicant?.locale ?? ''}
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.locale']}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<FormInput
						vertical={true}
						title="E-mail"
						name="email"
						value={applicant?.email ?? ''}
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.email']}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormInput
						vertical={true}
						title="Numer telefonu"
						name="phone"
						value={applicant?.phone ?? ''}
						onChange={onChange}
						disabled={disabled}
						error={errors?.['applicant.phone']}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormInput
						vertical={true}
						title="Faks"
						name="fax"
						value={applicant?.fax ?? ''}
						disabled={disabled}
						onChange={onChange}
						error={errors?.['applicant.fax']}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ApplicantForm;
