import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import API from 'apis/API';
import _ from 'lodash';
import { Accordion } from 'components/Main/Accordions';
import SchemaTable from 'components/Tables/SchemaTable';
import Routes from 'router/Routes';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Typography from 'components/Main/Typography';
import Tooltip from 'components/Main/Tooltip';
import { PreviewIcon } from 'assets/svg';

const useStyles = makeStyles(theme => ({
	accordion: {
		padding: '0 15px',
		backgroundColor: theme.palette.background.lightGray,
		marginBottom: '10px',
	},
	number: {
		display: 'inline-block',
		fontSize: 'inherit',
		color: theme.palette.turquoise,
	},
}));

const Result = ({
	registry,
	defaultParams,
	setResultsLoading,
}) => {
	const history = useHistory();
	const location = useLocation();

	const getParamsFromQueryString = () => {
		let paramsFromUrl = new URLSearchParams(history.location.search);

		let defaultParsedParams = {expanded: false, page: defaultParams.page, limit: defaultParams.limit};

		try {
			defaultParsedParams = JSON.parse(paramsFromUrl.get('registry_' + registry.id)) ?? defaultParsedParams;
		} catch(Exception) {
			console.log('cannot parse registry #'+registry.id+' query string params');
		}

		return defaultParsedParams;
	};

	const setParamsToQueryString = (expanded, newParams) => {
		let paramsFromUrl = new URLSearchParams(history.location.search);
		let searchParams = {};

		for(let entry of paramsFromUrl.entries()) {
			searchParams[entry[0]] = entry[1];
		}

		searchParams['registry_'+registry.id] = JSON.stringify({
			'expanded' : expanded,
			'page' : newParams.page,
			'limit' : newParams.limit
		});

		const paramsToReplace = new URLSearchParams(searchParams);
		history.replace({ pathname: location.pathname, search: paramsToReplace.toString() });
	};

	const paramsFromQueryString = getParamsFromQueryString();
	defaultParams = {...defaultParams, ...paramsFromQueryString};

	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [paginator, setPaginator] = useState(null);
	const [params, setParams] = useState(defaultParams);
	const columns = Object.values(registry.fields).filter(col => col.list && col.public);
	const [resultsNb, setResultsNb] = useState(null);
	const [isExpanded, setIsExpanded] = useState(paramsFromQueryString.expanded);

	useEffect(() => loadData(), [params]);

	useEffect(() => {
		if (resultsNb === null && paginator) {
			setResultsNb(paginator.total);
		}

	}, [paginator]);

	useEffect(() => {
		setParamsToQueryString(isExpanded, params);
	}, [params]);

	const loadData = () => {
		setPaginator(null);
		API.registries.cards.index(registry.id, params).then(response => {
			setPaginator(response.data);
		}).finally(() => {
			setLoading(false);
			setResultsLoading(prev => _.without(prev, registry.id));
		});
	};

	const renderRows = renderSchemaColumns => paginator && paginator.data.map((row, index) => (
		<TableRow key={index}>
			{renderSchemaColumns(row)}
			<TableCell>
				<Box display="flex">
					<Tooltip title="Podgląd">
						<Link
							to={Routes.Registries.Cards.Preview(row.registry_id, row.card_id)}
							aria-label={'Zobacz kartę ' + _.get(row, _.first(columns).name)}
						>
							<PreviewIcon fontSize="small" alt="Podgląd"/>
						</Link>
					</Tooltip>
				</Box>
			</TableCell>
		</TableRow>
	));

	const toggleExpand = (event, pIsExpanded) => {
		setIsExpanded(pIsExpanded);
		setParamsToQueryString(pIsExpanded, params);
	};

	return (
		<Accordion
			title={<>
				<Typography className={classes.number}>[{registry.number}]</Typography> {registry.name} ({(resultsNb === null ? 'wczytywanie' : resultsNb)} wyników)
			</>}
			className={classes.accordion}
			defaultExpanded={getParamsFromQueryString().expanded}
			onChange={toggleExpand}
			TransitionProps={{ unmountOnExit: true }}
		>
			{!loading && (
				<SchemaTable
					columns={columns}
					params={params}
					setParams={setParams}
					paginator={paginator}
					renderRows={renderRows}
					firstColumnLink={row => Routes.Registries.Cards.Preview(row.registry_id, row.card_id)}
					exportEndpoint={config => API.registries.cards.index(registry.id, params, config)}
					exportFilename="Karty"
					showFilters={false}
					showTotal={false}
				/>
			)}
		</Accordion>
	);
};
export default Result;
