import React from 'react';
import { Link } from 'react-router-dom';
import {
	makeStyles,
	AppBar,
	Box,
	useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

import { Logo, BarIcon } from 'assets/svg';
import Typography from 'components/Main/Typography';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Routes from 'router/Routes';

const items = [
	{
		title: 'Strona główna',
		href: Routes.Home,
	},
	{
		title: 'Lista rejestrów',
		href: Routes.Registries.List,
	},
	{
		title: 'Wyszukiwarka wniosków',
		href: Routes.Search,
	},
];

const Topbar = (props) => {
	const { className, setOpenSidebar, ...rest } = props;
	const location = useLocation();

	const isMobile = useMediaQuery('(max-width:1150px)');

	const useStyles = makeStyles((theme) => ({
		root: {
			boxShadow: 'none',
			padding: isMobile ? 20 : '20px 120px 15px',
			backgroundColor: theme.palette.white,
			zIndex: '101',
		},
		headerWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: '6px solid #1D1D1B',
			paddingBottom: 20,
		},
		logo: {
			padding: 4,
			margin: -4,
		},
		iconButton: {
			padding: 0,
			'&:hover': {
				background: 'none',
			},
			'& .MuiSvgIcon-root': {
				fontSize: 49,
			},
		},
		helpMenu: {
			marginRight: 20,
		},
		linkColor: {
			fontSize: 17,
			fontWeight: 400,
			color: '#000',
		},
		linkItem: {
			padding: 10,
			position: 'relative',

			'&:hover': {
				textDecoration: 'none',
				'& .MuiTypography-root': {
					color: 'rgba(0, 0, 0, 0.6)',
				},
			},
		},
		active: {
			position: 'absolute',
			bottom: 2,
			left: 10,
			right: 10,
			height: 3,
			backgroundColor: theme.palette.turquoise,
			transition: 'width 2s',
		},
	}));

	const classes = useStyles();

	const checkIsMatch = (item) => {
		if (item.href === '/') {
			return location.pathname === item.href;
		}

		return location.pathname.indexOf(item.href) > -1;
	};

	return (
		<AppBar
			className={clsx(classes.root, className)}
			{...rest}
			aria-label="Nagłówek"
		>
			<Box className={classes.headerWrapper}>
				<Link to="/" className={classes.logo}>
					<Logo alt="Logo" width={227} height={65}/>
				</Link>

				<Box display="flex" alignItems="center">
					{!isMobile &&
						items.map((item) => {
							const isMatch = checkIsMatch(item);

							return (
								<Link key={item.title} to={item.href} className={classes.linkItem}>
									<Typography variant="link" className={classes.linkColor}>
										{item.title}
									</Typography>
									{isMatch && (
										<span className={classes.active}></span>
									)}
								</Link>
							);
						})}

					{isMobile && (
						<BarIcon
							cursor={'pointer'}
							onClick={() => setOpenSidebar(true)}
							width={19}
							height={14}
						/>
					)}
				</Box>
			</Box>
		</AppBar>
	);
};

export default Topbar;
