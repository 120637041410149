import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, List, ListItem } from '@material-ui/core';

import Typography from 'components/Main/Typography';
import Tooltip from 'components/Main/Tooltip';

const useStyles = makeStyles((theme) => ({
	label: {
		padding: theme.spacing(1, 4),
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		padding: theme.spacing(2, 4),
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		borderRadius: 0,
		'& .MuiButton-startIcon': {
			width: 45,
			height: 45,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	active: {
		color: theme.palette.secondary.main,
		position: 'relative',
		'& .MuiButton-startIcon': {
			borderRadius: 3,
			backgroundColor: `${theme.palette.secondary.main}20`,
			'& svg': {
				fill: theme.palette.secondary.main,
			},
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			top: '50%',
			width: 3,
			height: 25,
			transform: 'translate(0, -50%)',
			background: theme.palette.secondary.main,
			borderRadius: '0px 3px 3px 0px',
		},
	},
	subListItem: {
		paddingLeft: '20px',
		paddingBottom: 2,
		paddingTop: 2,
	},
	ellipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: window.isMobile ? 142 : 148,
	},
	tooltip: {
		marginBottom: 0,
		background: '#17253FE5',
		borderRadius: 0,
		fontSize: 12,
	},
	subListItemHeight: {
		height: 45,
		paddingTop: 0,
		paddingBottom: 0,
	},
}));

const CustomRouterLink = forwardRef((props, ref) => (
	<div ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</div>
));

CustomRouterLink.displayName = 'CustomRouterLink';

const SidebarNav = (props) => {
	const { pages, className, ...rest } = props;
	const location = useLocation();
	const classes = useStyles();

	const checkIsMatch = (item) => {
		return (
			location.pathname.indexOf(item.href) > -1 ||
			location.pathname.indexOf(item?.path) > -1
		);
	};

	const renderList = (list) =>
		list.map((item, index) => {
			const isMatch = checkIsMatch(item);

			return item.children ? (
				<Fragment key={index}>
					<Typography
						key={index}
						variant="menuLabel"
						className={classes.label}
					>
						&gt;&gt; {item.title}
					</Typography>
					{renderList(item.children)}
				</Fragment>
			) : (
				<Fragment key={index}>
					<ListItem className={classes.listItem} disableGutters>
						<Button
							className={clsx({
								[classes.button]: true,
								[classes.active]: isMatch,
								[classes.subListItemHeight]: item?.isSubList,
							})}
							component={CustomRouterLink}
							to={item.href}
							startIcon={item?.icon}
						>
							{item.tooltip ? (
								<Tooltip
									title={item.title}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									<Typography
										variant="h4"
										className={
											item.ellipsis && classes.ellipsis
										}
									>
										{item.title}
									</Typography>
								</Tooltip>
							) : (
								<Typography
									variant="h4"
									className={
										item.ellipsis && classes.ellipsis
									}
								>
									{item.title}
								</Typography>
							)}
						</Button>
					</ListItem>
				</Fragment>
			);
		});

	return (
		<List
			{...rest}
			className={clsx(classes.root, className)}
			style={{
				height: 'calc(100vh - 85px)',
				overflowY: 'auto',
			}}
		>
			{renderList(pages)}
		</List>
	);
};

SidebarNav.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired,
};

export default SidebarNav;
