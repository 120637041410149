import React, { useState, useEffect } from 'react';
import {
	Box,
	Card,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	makeStyles, Table as MuiTable,
} from '@material-ui/core';

import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';
import DateInput from 'components/Form/DateInput';
import TableLabel from 'components/Main/TableLabel';
import ExportButtons from 'components/Buttons/ExportButtons';
import { Pagination } from '@material-ui/lab';
import Typography from 'components/Main/Typography';
import Progress from 'components/Main/Progress';
import PropTypes from 'prop-types';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import FilterRow from 'components/Tables/FilterRow';

const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: 0,
		boxShadow: 'none',
	},
	cardFooter: {
		padding: theme.spacing(3, window.isMobile ? 0 : 4),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiPaginationItem-root': {
			height: window.isMobile ? 26 : 32,
			minWidth: window.isMobile ? 26 : 32,
		},
	},
	root: {
		border: '1px solid #D0D0D0',
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root':
			{
				borderBottom: 0,
			},
		'& .MuiTableCell-root': {
			verticalAlign: 'top',
		},
	},
	thead: {
		background: theme.palette.gray,
		borderBottom: '1px solid #D0D0D0',
		'& .MuiTableCell-root': {
			borderBottom: 0,
		},
		'& .MuiTableRow-root:last-child': {
			borderTop: '2px solid #fff',
		},
		'& tr:first-child, & tr:last-child': {
			background: '#014164',
			'& .MuiTypography-root': {
				color: theme.palette.white,
				fontWeight: 600,
			},
		},
		'& .MuiTableSortLabel-icon': {
			color: `${theme.palette.white} !important`,
		},
	},
	tbody: {
		'& .MuiTableRow-root:nth-of-type(even)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	total: {
		marginTop: '-1.5rem',
		marginBottom: '0.5rem',
	},
	focusable: {
		'&:focus-visible': {
			outline: '2px solid #f00',
		},
	},
}));

const SchemaTable = ({
	columns,
	params,
	setParams,
	initialFilters = {},
	paginator,
	renderRows,
	exportEndpoint,
	exportFilename,
	firstColumnLink,
	showTotal = true,
	showFilters = true,
	className,
}) => {
	const classes = useStyles();
	const [defaultFilters, setDefaultFilters] = useState(initialFilters);

	useEffect(() => {
		let filters = {};
		columns.forEach(field => {
			filters[field.name] = field.type === 'date' ? { from: null, to: null } : '';
		});
		setDefaultFilters(filters);
	}, []);

	const sortHandler = column => () => {
		setParams(prev => ({
			...prev,
			page: 1,
			column,
			direction: prev.column === column ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'asc',
		}));
	};

	const renderSearchField = (field, filters, filterChangeHandler) => {
		const { type, name, options } = field;

		if (type === 'select') {
			return (
				<Select
					options={options}
					name={name}
					value={filters[name] ?? ''}
					onChange={filterChangeHandler}
					displayEmpty
					emptyLabel="dowolny"
					style={{ width: '100%' }}
				/>
			);
		}

		if (type === 'date') {
			return (
				<Box display="flex">
					<DateInput
						placeholder="od"
						name={`${name}.from`}
						value={filters[name]?.['from'] || null}
						onChange={filterChangeHandler}
						style={{ minWidth: filters[name]?.['from'] ?  128 : 64 }}
					/>
					<DateInput
						placeholder="do"
						name={`${name}.to`}
						value={filters[name]?.['to'] || null}
						onChange={filterChangeHandler}
						style={{ minWidth: filters[name]?.['to'] ? 128 : 64, marginLeft: -1 }}
					/>
				</Box>
			);
		}

		return (
			<FormInput
				placeholder="wpisz"
				name={name}
				value={filters[name] ?? ''}
				onChange={filterChangeHandler}
				fullWidth
			/>
		);
	};

	const renderLabel = ({ type, label }) => {
		let customLabel;
		switch (type) {
			case 'card_nr':
				customLabel = 'NR KARTY';
				break;
			case 'applicant':
				customLabel = 'Wnioskodawca';
				break;
			default:
				customLabel = label;
		}
		return customLabel;
	};

	const headerRow = <TableRow>
		{columns.map(field => (
			<TableCell key={field.name} width={field.type === 'card_nr' ? 100 : undefined}>
				<TableSortLabel
					className={classes.focusable}
					active={field.name === params.column}
					direction={params.direction}
					onClick={sortHandler(field.name)}
					aria-label={`Kliknij aby posortować kolumnę ${field.label} ` + (params.direction === 'asc' ? 'malejąco' : 'rosnąco')}
					tabIndex="0"
				>
					<TableLabel>{renderLabel(field)}</TableLabel>
				</TableSortLabel>
			</TableCell>
		))}
		<TableCell width={100}>
			<TableLabel>Akcje</TableLabel>
		</TableCell>
	</TableRow>;

	const renderFilterRow = (filters, filterChangeHandler, clearFiltersButton) => (
		<TableRow>
			{columns.map((field) => (
				<TableCell key={field.name}>
					{renderSearchField(field, filters, filterChangeHandler)}
				</TableCell>
			))}
			<TableCell>
				{clearFiltersButton}
			</TableCell>
		</TableRow>
	);

	const filterRow = showFilters && (
		<FilterRow setParams={setParams} defaultFilters={defaultFilters} renderRow={renderFilterRow}/>
	);

	const renderSchemaColumns = row => columns.map((field, i) => (
		<TableCell key={field.name} {...(field.name === 'card_nr' ? { 'data-nowrap': 'true' } : {})}>
			<ConditionalWrapper condition={!i && firstColumnLink} wrapper={v => <Link to={firstColumnLink(row)}>{v}</Link>}>
				{row[field.name]}
			</ConditionalWrapper>
		</TableCell>
	));

	return (
		<Card className={classes.card}>
			{showTotal && (
				<Typography className={classes.total}>
					Ilość wyników: {paginator ? paginator.total : '~'}
				</Typography>
			)}

			<TableContainer className={clsx(classes.root, className)}>
				<MuiTable>
					<TableHead className={classes.thead}>
						{headerRow}
						{filterRow}
						{filterRow && headerRow}
					</TableHead>
					<TableBody className={classes.tbody}>
						{paginator?.total > 0 ? (
							renderRows(renderSchemaColumns)
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
									{paginator ? 'Brak wyników' : <Progress status={true}/>}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</MuiTable>
			</TableContainer>

			{exportEndpoint && (
				<ExportButtons
					endpoint={exportEndpoint}
					filename={exportFilename}
					perPageCount={params.limit}
					onChangeCount={event => setParams(prev => ({ ...prev, page: 1, limit: event.target.value }))}
				/>
			)}
			{paginator && paginator.last_page > 1 && (
				<Box className={classes.cardFooter}>
					<Pagination
						shape="rounded"
						color="primary"
						count={paginator.last_page}
						page={params.page}
						onChange={(event, page) => setParams(prev => ({ ...prev, page }))}
					/>
				</Box>
			)}
		</Card>
	);
};

SchemaTable.propTypes = {
	columns: PropTypes.array,
	params: PropTypes.object,
	setParams: PropTypes.func,
	paginator: PropTypes.object,
	renderRows: PropTypes.func,
	exportEndpoint: PropTypes.func,
	exportFilename: PropTypes.string,
	firstColumnLink: PropTypes.func,
	showTotal: PropTypes.bool,
	showFilters: PropTypes.bool,
	className: PropTypes.string,
};


export default SchemaTable;
