import React from 'react';
import clsx from 'clsx';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Drawer, useMediaQuery } from '@material-ui/core';

import { Logo, TimesBlack } from 'assets/svg';

import Routes from 'router/Routes';
import SidebarNav from './SidebarNav';

const Sidebar = (props) => {
	const { open, variant, className, setOpenSidebar } = props;

	const isMobile = useMediaQuery('(max-width:1150px)');

	const useStyles = makeStyles(() => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		logo: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: isMobile ? 20 : '20px 40px 15px',
			borderBottom: isMobile ? 'none' : '1px solid #D0D0D0',
		},
	}));

	const classes = useStyles();

	const items = [
		{
			title: 'Strona główna',
			href: Routes.Home,
		},
		{
			title: 'Lista rejestrów',
			href: Routes.Registries.List,
		},
		{
			title: 'Wyszukiwarka wniosków',
			href: Routes.Search,
		},
	];

	return (
		<>
			<Drawer
				anchor="left"
				open={open}
				variant={variant}
				PaperProps={{
					style: {
						width: 323,
						height: '100vh'
					},
					'aria-label': 'Menu',
				}}
				onClose={() => setOpenSidebar(!open)}
			>
				<div className={clsx(classes.root, className)}>
					<div className={classes.logo}>
						<Link to={Routes.Dashboard}>
							<Logo alt="Logo" width={227} height={65} />
						</Link>

						<TimesBlack
							cursor={'pointer'}
							onClick={() => setOpenSidebar(false)}
						/>
					</div>

					<SidebarNav pages={items} />
				</div>
			</Drawer>
		</>
	);
};

Sidebar.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	variant: PropTypes.string.isRequired,
};

export default withRouter(Sidebar);
