import { Grid } from '@material-ui/core';
import { FormInput } from 'components/Form/BoxInputs';
import React from 'react';

const RepresentativeForm = ({ representative, onChange, disabled = false, errors = {} }) => {
	// const { titles } = useContext(GlobalContext);

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4} md={2}>
					<FormInput
						vertical={true}
						title="Tytuł"
						name="title_id"
						value={representative?.title?.label ?? ''}
						required
						onChange={onChange}
						disabled={disabled}
						error={errors?.['representative.title_id']}
					/>
				</Grid>
				<Grid item xs={12} sm={4} md={5}>
					<FormInput
						vertical={true}
						title="Imię"
						name="name"
						value={representative?.name ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						onChange={onChange}
						disabled={disabled}
						error={errors?.['representative.name']}
					/>
				</Grid>
				<Grid item xs={12} sm={4} md={5}>
					<FormInput
						vertical={true}
						title="Nazwisko"
						name="last_name"
						value={representative?.last_name ?? ''}
						inputProps={{ 'aria-required': true }}
						required
						onChange={onChange}
						disabled={disabled}
						error={errors?.['representative.last_name']}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormInput
						vertical={true}
						title="Numer telefonu"
						name="phone"
						value={representative?.phone ?? ''}
						onChange={onChange}
						disabled={disabled}
						error={errors?.['representative.phone']}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormInput
						vertical={true}
						title="E-mail"
						name="email"
						value={representative?.email ?? ''}
						onChange={onChange}
						disabled={disabled}
						error={errors?.['representative.email']}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default RepresentativeForm;
