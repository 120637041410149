import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Breadcrumbs as MuiBreadcrumbs, Box } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import Routes from 'router/Routes';

const useStyles = makeStyles(theme => ({
	container: {
		marginLeft: -4,
		marginTop: 6,
		marginBottom: 28,
		'& .MuiBreadcrumbs-separator': {
			marginLeft: 4,
			marginRight: 4,
		}
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		padding: 4,
	},
	icon: {
		marginRight: 5
	},
	active: {
		color: theme.palette.info.main
	},
	clickableItem: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline #000000'
		}
	},
}));

const Breadcrumbs = props => {
	const classes = useStyles();
	const {
		separator = <NavigateNext fontSize="small"/>,
		breadcrumbs = [],
		isDashboard = false,
		isShowDashboard = true,
		handleClick,
		...rest
	} = props;

	useEffect(() => {
		const item = _.last(breadcrumbs);
		document.title = _.trim(item?.title + ' - GMO', ' -');
	}, [breadcrumbs]);

	const renderItem = (title, active = false) => (
		<Typography variant="link" component="span" color="textPrimary" className={clsx({ [classes.active]: active })}>
			{title}
		</Typography>
	);

	return (
		<Box id="breadcrumb" className={classes.container}>
			<MuiBreadcrumbs aria-label="breadcrumb" separator={separator} {...rest}>
				{isShowDashboard &&
					(
						isDashboard ?
							<Box className={classes.link}>
								{renderItem('Strona główna')}
							</Box>
							:
							<Link to={Routes.Dashboard} className={classes.link}>
								{renderItem('Strona główna', true)}
							</Link>
					)};
				{breadcrumbs.map((item, i) => (
					item.to ?
						(
							<Link key={i} to={item.to} className={classes.link}>
								{renderItem(item.title, true)}
							</Link>
						)
						: (
							<Box
								key={i}
								display="flex"
								alignItems="center"
								className={clsx({ [classes.clickableItem]: _.has(item, 'folderId') }, classes.link)}
								onClick={() => _.has(item, 'folderId') ? handleClick(item) : ''}
							>
								{renderItem(item.title, _.has(item, 'folderId') ? true : false)}
							</Box>
						)
				))}
			</MuiBreadcrumbs>
		</Box>
	);
};

Breadcrumbs.propTypes = {
	separator: PropTypes.node,
	breadcrumbs: PropTypes.array,
	isDashboard: PropTypes.bool,
	isShowDashboard: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default Breadcrumbs;
