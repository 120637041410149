import React, { useEffect, useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import _ from 'lodash';
import API from 'apis/API';
import { useLocation } from 'react-router-dom';
import Header from 'components/Main/Header';
import ErrorPage, { hasErrorPage } from 'pages/ErrorPage';
import Progress from 'components/Main/Progress';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Logo1 from 'assets/svg/logo1.png';

const useStyles = makeStyles(theme => ({
	content: {
		fontFamily: 'Open Sans',
		'& ul,ol': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			marginLeft: theme.spacing(3),
		},
		'& h2': {
			marginTop: '0.8em',
			marginBottom: '0.8em',
		},
		'& h3': {
			marginTop: '0.8em',
			marginBottom: '0.3em',
		},
	},
	headTextMb: {
		marginBottom: 35,
		marginTop: 35,
	},
	image: {
		float: 'right',
		marginLeft: theme.spacing(4),
	},
}));

const Page = () => {
	const [loading, setLoading] = useState(true);
	const [responseError, setResponseError] = useState(0);
	const location = useLocation();
	const classes = useStyles();

	const [page, setPage] = useState({
		slug: '',
		title: '',
		content: '',
	});

	useEffect(() => {
		API.pages.show(_.trim(location.pathname, '/')).then((res) => {
			const page = res.data.data;
			setPage(page);
		}).catch(error => {
			setResponseError(error.response.status);
		}).finally(() => setLoading(false));
	}, [location.pathname]);

	if (loading) {
		return <Progress status={true}/>;
	}
	if (hasErrorPage(responseError)) {
		return <ErrorPage code={responseError}/>;
	}

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ title: page.title, to: '' }]}/>

			<Header title={page.title} className={classes.headTextMb}/>

			<Box display="flex" flexDirection="column" alignItems="center">
				<div>
					{page.slug === 'funding' && (
						<img
							className={classes.image} src={Logo1} width={177} height={65}
							alt="Narodowy Fundusz Ochrony Środowiska i Gospodarki Wodnej"
						/>
					)}
					<div
						className={classes.content}
						dangerouslySetInnerHTML={{ __html: page.content }}
					/>
				</div>
			</Box>
		</>
	);
};

export default Page;
