import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router';

import API from 'apis/API';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';

import Filters from 'pages/Search/Filters';
import Progress from 'components/Main/Progress';
import ErrorPage, { hasErrorPage } from 'pages/ErrorPage';
import Typography from 'components/Main/Typography';
import Result from 'pages/Search/Result';

const useStyles = makeStyles((theme) => ({
	headerMb: {
		marginBottom: 25,
	},
	accordion: {
		padding: '0 15px',
		backgroundColor: theme.palette.background.lightGray,
		marginBottom: '10px',
	},
	filterResultMb: {
		marginBottom: 25,
		marginLeft: 15
	},
}));

const breadcrumbs = [{ title: 'Wyszukiwarka wniosków' }];

const Form = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [responseError, setResponseError] = useState(0);
	const [registries, setRegistries] = useState({});
	const [registryIds, setRegistryIds] = useState([]);

	const history = useHistory();
	const location = useLocation();

	let paramsFromUrl = new URLSearchParams(history.location.search);
	let defaultParams = {
		column: 'card_nr',
		direction: 'desc',
		limit: 10,
		page: 1,
		filters: {},
		registryIds: [],
	};

	try {
		if (paramsFromUrl.get('q')) {
			defaultParams = JSON.parse(decodeURIComponent(paramsFromUrl.get('q'))) ?? defaultParams;
		}
	} catch (Exception) {
		console.log('failed to decode query string!');
	}

	const [params, setParams] = useState(defaultParams );

	const defaultFilters = {
		'phrase': '',
		'card_nr.reg_nb': '',
		'card_nr.card_nb': '',
		'card_nr.year': '',
		'threat_category': '',
		'submit_date': {
			from: null,
			to: null,
		},
		'decision_date': {
			from: null,
			to: null,
		},
		'applicant.name': '',
		'representative.full_name': '',
		'applicant.city': '',
		'applicant.voivodeship_id': '',
	};
	const resultsRef = useRef(null);
	const [showResults, setShowResults] = useState(false);
	const [resultsLoading, setResultsLoading] = useState([]);
	const [resultsKey, setResultsKey] = useState(+new Date());

	const setFiltersToQueryString = (filters) => {
		const paramsToReplace = new URLSearchParams({q: encodeURIComponent(JSON.stringify(filters))});
		history.replace({ pathname: location.pathname, search: paramsToReplace.toString() });
	};

	useEffect(() => {
		API.registries.index({ limit: 20, with_schema: true }).then(res => {
			// FIXME: maybe pagination for higher number of Registries (if that ever happens)
			let registries = {};
			res.data?.data.forEach(registry => {
				registries[registry.id] = registry;
			});
			setRegistries(registries);
		}).catch(error => {
			setResponseError(error.response.status);
		}).finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (!_.isEmpty(registries) && !_.isEmpty(params.registryIds)) {
			submitHandler(true);
		}
	}, [registries]);


	useEffect(() => {
		resultsRef.current && resultsRef.current.scrollIntoView();
	}, [registryIds]);

	const submitHandler = (skipParamsSet) => {
		setRegistryIds(_.keys(_.pick(registries, params.registryIds)));
		setResultsKey(+new Date());
		setShowResults(true);
		setResultsLoading(params.registryIds);

		if (!skipParamsSet) {
			setFiltersToQueryString(params);
		}
	};

	if (isLoading) {
		return <Progress status={true}/>;
	}
	if (hasErrorPage(responseError)) {
		return <ErrorPage code={responseError}/>;
	}

	return (
		<>
			<Box>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
				<Header title="Wyszukiwarka wniosków" className={classes.headerMb}/>

				<Filters
					title="Wyszukaj w rejestrach"
					params={params}
					setParams={setParams}
					defaultFilters={defaultFilters}
					registries={registries}
					loading={!!resultsLoading.length}
					submitHandler={() => submitHandler(false)}
				/>

				<Box ref={resultsRef}>
					{showResults && registryIds.length ? <>
						<Typography variant="h3" className={classes.filterResultMb}>
							Wyniki wyszukiwania
						</Typography>
						{registryIds.map(registryId => (
							<Result
								key={registryId + resultsKey}
								registry={registries[registryId]}
								defaultParams={_.omit(params, 'registryIds')}
								setResultsLoading={setResultsLoading}
							/>
						))}
					</> : ''}
				</Box>
			</Box>
		</>
	);
};

export default Form;
