import axios from 'axios';

const API = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
});

API.interceptors.request.use(
	(config) => {
		if (
			process.env.NODE_ENV === 'development' &&
			process.env.REACT_APP_XDEBUG_SESSION
		) {
			config.params = config.params || {};
			config.params.XDEBUG_SESSION = process.env.REACT_APP_XDEBUG_SESSION;
		}
		return config;
	},
	(error) => Promise.reject(error)
);
/**
 * Registires
 */
API.registries = {
	index: (params = {}, config = {}) =>
		API.get('/public/registries', { ...config, params: { ...config?.params, ...params } }),
	show: (id) => API.get(`/public/registries/${id}`),
	/**
	 * Cards
	 */
	cards: {
		index: (id, params, config = {}) =>
			API.get(`/public/cards/list/${id}`, { ...config, params: { ...config?.params, ...params } }),
		show: (id) => API.get(`/public/cards/${id}`),
		years: (id) => API.get(`/public/cards/years/${id}`),
		download: (cardId, uuid) =>
			API.get(`/public/attachment/${cardId}/${uuid}`, { responseType: 'blob' }),
	},
};

/**
 * Applicants
 */
API.applicants = {
	show: (id) => API.get(`/public/applicants/${id}`),
};

/**
 * Pages
 */
API.pages = {
	show: (slug) => API.get(`/public/pages/${slug}`),
};

/**
 * Dict
 */
API.dict = {
	all: () => API.get('/dict/all'),
};

export default API;
