import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastContainer } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import 'moment/locale/pl';

import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/css/index.css';

import theme from 'theme';
import Progress from 'components/Main/Progress';

import { GlobalContextProvider } from 'context/GlobalContext';

moment.tz.setDefault(process.env.REACT_APP_TIMEZONE);

const App = () => (
	<MuiPickersUtilsProvider utils={MomentUtils} locale="pl">
		<ThemeProvider theme={theme}>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Suspense fallback={<Progress status={true} />}>
					<GlobalContextProvider />
					<ToastContainer
						position="top-center"
						autoClose={10000}
						theme="colored"
						style={{ fontSize: 18 }}
					/>
				</Suspense>
			</BrowserRouter>
		</ThemeProvider>
	</MuiPickersUtilsProvider>
);

export default App;
