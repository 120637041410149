import { Box, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Routes from 'router/Routes';
import { LinkIcon } from 'assets/svg';
import Typography from 'components/Main/Typography';
import React, { useEffect, useState } from 'react';

const RegistryCard = ({ name, value }) => {
	const classes = useStyles();
	const [decisionPlace, setDecisionPlace] = useState(null);

	useEffect(() => {
		setDecisionPlace(value);
	}, []);

	const mainLabel = decisionPlace?.label?.[0];
	const otherLabels = decisionPlace?.label?.slice(1);

	return (
		<Box key={name} className={classes.box}>
			{!!decisionPlace && (
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Box display="flex" alignItems="center" className={classes.decision}>
							<Link to={Routes.Registries.Cards.Edit(decisionPlace.registry_id, decisionPlace.card_id)} target="_blank">
								<LinkIcon/>
							</Link>
							<Typography className={classes.mainLabel}>{mainLabel}</Typography>
						</Box>
					</Grid>

					{otherLabels && (
						<Grid container item xs={12} sm={6}>
							{otherLabels.map((label, index) => (
								<Grid key={index} item xs={12} alignItems="center" style={{ display: 'flex' }}>
									<Box display="flex" alignItems="center">
										{typeof label === 'object' ? (
											Object.entries(label).map(([k, v]) => <React.Fragment key={k}>
												<Typography className={classes.label}>{k}:</Typography>
												<Typography className={classes.value}>{v.length ? v : '-'}</Typography>
											</React.Fragment>)
										) : (
											<Typography className={classes.label}>{label}</Typography>
										)}
									</Box>
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	box: {
		marginBottom: theme.spacing(2),
	},
	decision: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		height: '100%',
	},
	mainLabel: {
		marginLeft: 10,
		lineHeight: 1.2,
	},
	label: {
		marginRight: 10,
		lineHeight: 1.6,
	},
	value: {
		fontWeight: 600,
		lineHeight: 1.2,
	},
}));

export default RegistryCard;
