import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';

import { Logo } from 'assets/svg';
import Logo1 from 'assets/svg/logo1.png';

import Typography from 'components/Main/Typography';
import Routes from 'router/Routes';
import theme from 'theme';

const Footer = () => {
	const useStyles = makeStyles(() => ({
		root: {
			paddingBottom: 20,
			zIndex: 1200,
		},
		helpMenu: {
			marginRight: 20,
		},
		link: {
			display: 'inline-block',
			padding: 4,
			margin: -4,
		},
		linkColor: {
			fontSize: 18,
			fontWeight: 400,
			color: '#000',
		},
		linkItem: {
			display: 'inline-block',
			padding: 4,
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(2),

			'&:hover': {
				textDecoration: 'none',
				'& .MuiTypography-root': {
					color: 'rgba(0, 0, 0, 0.6)',
				},
			},
		},
	}));

	const classes = useStyles();

	return (
		<Grid container className={classes.root} id="footer">
			<Grid item lg={5} md={6} xs={12} style={{ marginBottom: 40 }}>
				<Link to="/" style={{ marginRight: 26 }} className={classes.link}>
					<Logo alt="Logo" width={227} height={65}/>
				</Link>
				<Link to={Routes.Pages.Funding} className={classes.link}>
					<img src={Logo1} width={177} height={65} alt="Logo1"/>
				</Link>
			</Grid>

			<Grid item lg={7} md={6} xs={12}>
				<Grid container>
					<Grid item lg={4} md={6} sm={4} xs={12}>
						<Box>
							<Link to={Routes.Pages.Regulations} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Regulamin
								</Typography>
							</Link>
						</Box>
						<Box>
							<Link to={Routes.Pages.CookiesPolicy} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Polityka cookies
								</Typography>
							</Link>
						</Box>
						<Box>
							<Link to={Routes.Pages.PrivacyPolicy} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Polityka prywatności
								</Typography>
							</Link>
						</Box>
					</Grid>

					<Grid item lg={4} md={6} sm={4} xs={12}>
						<Box>
							<Link to={Routes.Pages.DeclarationOfAvailability} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Deklaracja dostępności
								</Typography>
							</Link>
						</Box>
						<Box>
							<Link to={Routes.Pages.Funding} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Finansowanie
								</Typography>
							</Link>
						</Box>
						<Box>
							<Link to={Routes.Pages.InformationClause} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Klauzula informacyjna
								</Typography>
							</Link>
						</Box>
					</Grid>

					<Grid item lg={4} md={6} sm={4} xs={12}>
						<Box>
							<Link to={Routes.Registries.List} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Rejestry GMO i GMM
								</Typography>
							</Link>
						</Box>
						<Box>
							<Link to={Routes.Search} className={classes.linkItem}>
								<Typography variant="link" className={classes.linkColor}>
									Wyszukiwarka wniosków
								</Typography>
							</Link>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
