import React from 'react';
import { makeStyles, Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from './Typography';

const useStyles = makeStyles(() => ({
	header: {
		display: 'flex',
		paddingBottom: 8,
	},
	headerTextPb: {
		paddingBottom: 20,
	},
}));

const Header = (props) => {
	const { title, className, titleVariant = 'h1' } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Box className={clsx(classes.header, className)} id="header">
			<Box>
				<Typography
					variant={titleVariant}
					className={classes.headerTextPb}
				>
					{title}
				</Typography>

				<Box
					borderBottom={`6px solid ${theme.palette.orange}`}
					width={180}
				></Box>
			</Box>
		</Box>
	);
};

Header.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,
};

export default Header;
