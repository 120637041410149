import React from 'react';
import { makeStyles, Box, Grid, useTheme } from '@material-ui/core';

import Typography from 'components/Main/Typography';

const useStyles = makeStyles((theme) => ({
	section: {
		paddingTop: theme.spacing(3),
	},
	headerWrapperText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.lightGray,
	},
	headerTextPb: {
		paddingBottom: 20,
		fontSize: 46,
		'@media (max-width: 600px)': {
			fontSize: 36,
		},
		'@media (max-width: 425px)': {
			fontSize: 26,
		},
	},
	headerContainerMb: {
		marginBottom: 40,
	},
	card: {
		color: 'inherit',
		backgroundColor: theme.palette.background.lightGray,
		padding: '20px 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		minHeight: '150px',
	},
	boxPadding: {
		padding: '30px', // Padding value for screens equal to or less than medium breakpoint (md)
		[theme.breakpoints.down('md')]: {},
	},
	gridStyle: {
		position: 'relative',
		'&:not(:last-child):before': {
			content: '" "',
			position: 'absolute',
			width: '18px',
			height: '18px',
			borderRadius: '100%',
			border: '3px solid #F47920',
			boxSizing: 'border-box',
			right: '7px',
			top: '50%',
			marginTop: '-9px',
			backgroundColor: 'white',
		},
		'&:not(:first-child):after': {
			content: '" "',
			position: 'absolute',
			width: '18px',
			height: '18px',
			borderRadius: '100%',
			border: '3px solid #F47920',
			boxSizing: 'border-box',
			left: '7px',
			top: '50%',
			marginTop: '-9px',
			backgroundColor: 'white',
		},
		'&:not(:last-child) > div:before': {
			content: '" "',
			position: 'absolute',
			width: '14px',
			height: '3px',
			backgroundColor: '#F47920',
			boxSizing: 'border-box',
			right: '-7px',
			top: '50%',
			marginTop: '-1.5px',
		},
		'&:not(:first-child) > div:after': {
			content: '" "',
			position: 'absolute',
			width: '14px',
			height: '3px',
			backgroundColor: '#F47920',
			boxSizing: 'border-box',
			left: '-7px',
			top: '50%',
			marginTop: '-1.5px',
		},
	},
	note: {
		paddingLeft: 20,
		maxWidth: 980,
		'& li::marker': {
			color: theme.palette.turquoise,
		},
	},
}));

const Home = () => {
	const classes = useStyles();
	const theme = useTheme();

	document.title = 'Strona główna - GMO';

	return (
		<>
			<Box className={classes.section}>
				<Grid container className={classes.headerContainerMb}>
					<Grid item md={5} xs={12}>
						<img
							width="100%"
							height="100%"
							src="/images/landing_background.png"
						/>
					</Grid>
					<Grid item md={7} xs={12} className={classes.headerWrapperText}>
						<Box className={classes.boxPadding}>
							<Typography variant="h1" className={classes.headerTextPb}>
								Witamy w systemie <br /> “Elektroniczne rejestry{' '}
								<br />
								mikroorganizmów i organizmów <br /> genetycznie
								zmodyfikowanych”
							</Typography>

							<Box
								borderBottom={`6px solid ${theme.palette.orange}`}
								width={180}
							></Box>
						</Box>
					</Grid>
				</Grid>

				<Box marginBottom={4}>
					<Typography variant="bodyS" style={{ fontWeight: '500', marginBottom: 15 }}>
						System powstał w celu umożliwienia społeczeństwu
						aktywnego udziału w procesie podejmowania decyzji
						dotyczących:
					</Typography>
					<ul className={classes.note}>
						<li>
							<Typography variant="bodyS" style={{ fontWeight: '500' }}>
								wykorzystywania mikroorganizmów i organizmów
								genetycznie zmodyfikowanych w systemach
								zamkniętych typu laboratoria, szklarnie,
								zwierzętarnie,
							</Typography>
						</li>
						<li>
							<Typography variant="bodyS" style={{ fontWeight: '500' }}>
								uwalniania organizmów genetycznie
								zmodyfikowanych do środowiska w celach
								doświadczalnych,
							</Typography>
						</li>
						<li>
							<Typography variant="bodyS" style={{ fontWeight: '500' }}>
								wprowadzania produktów GMO do obrotu,
							</Typography>
						</li>
						<li>
							<Typography variant="bodyS" style={{ fontWeight: '500' }}>
								prowadzenia upraw roślin genetycznie
								zmodyfikowanych.
							</Typography>
						</li>
					</ul>
				</Box>

				<Box>
					<Typography variant="h1" style={{ marginBottom: 20 }}>
						Jak korzystać z rejestrów?
					</Typography>
					<Grid container spacing={4}>
						<Grid item md={3} sm={6} xs={12} className={classes.gridStyle}>
							<Box className={classes.card}>
								<Typography variant="subtitle2">
									Przejdź do listy rejestrów
								</Typography>
							</Box>
						</Grid>
						<Grid item md={3} sm={6} xs={12} className={classes.gridStyle}>
							<Box className={classes.card}>
								<Typography variant="subtitle2">
									Wybierz rejestr, który Cię interesuje
								</Typography>
							</Box>
						</Grid>
						<Grid item md={3} sm={6} xs={12} className={classes.gridStyle}>
							<Box className={classes.card}>
								<Typography variant="subtitle2">
									Skorzystaj z filtrów
								</Typography>
							</Box>
						</Grid>
						<Grid item md={3} sm={6} xs={12} className={classes.gridStyle}>
							<Box className={classes.card}>
								<Typography variant="subtitle2">
									Przeglądaj dane lub pobierz je
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default Home;
