const Routes = {
	Dashboard: '/',
	Home: '/',
	Registries: {
		List: '/registries',
		Cards: {
			List: (id = ':id') => `/registries/${id}/cards`,
			Preview: (registerId = ':register_id', id = ':id') =>
				`/registries/${registerId}/cards/${id}`,
			Edit: (registerId = ':register_id', id = ':id') =>
				`/registries/${registerId}/cards/${id}`,
		},
	},
	NotFound: '/404',
	Pages: {
		Regulations: '/regulations',
		PrivacyPolicy: '/privacy-policy',
		DeclarationOfAvailability: '/declaration-of-availability',
		CookiesPolicy: '/cookies-policy',
		Funding: '/funding',
		InformationClause: '/information-clause',
	},
	Search: '/search',
};

export default Routes;
