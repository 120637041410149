import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
	Box,
	Divider,
	Grid,
	SvgIcon,
	makeStyles,
} from '@material-ui/core';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { BaseInput, FormInput, DateInput } from 'components/Form/BoxInputs';
import FormError from 'components/Main/FormError';
import Header from 'components/Main/Header';
import Typography from 'components/Main/Typography';
import Routes from 'router/Routes';
import API from 'apis/API';
import ApplicantForm from 'pages/Cards/Form/ApplicantForm';
import RepresentativeForm from 'pages/Cards/Form/RepresentativeForm';
import { LinkIcon, Printer, VisibleIcon } from 'assets/svg';
import standarizeDate from 'utils/standarizeDate';
import Validator, { LengthLimit, Required } from 'utils/Validator';
import ErrorPage, { hasErrorPage } from 'pages/ErrorPage';
import Progress from 'components/Main/Progress';
import Attachments from 'pages/Cards/Form/Attachments';
import RegistryCard from 'pages/Cards/Form/RegistryCard';

const useStyles = makeStyles((theme) => ({
	errorFormMr: {
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
		marginRight: theme.spacing(8),
		marginBottom: 20,
	},
	section: {
		background: theme.palette.background.lightGray,
		padding: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
		marginRight: theme.spacing(8),
		marginBottom: 30,
	},
	headerMb: {
		marginBottom: 30,
	},
	gutterBottom: {
		marginBottom: 10,
	},
	requiredStar: {
		color: theme.palette.secondary.main,
		marginLeft: 5,
		fontWeight: 'bold',
	},
	radio: {
		flexDirection: 'row',
		marginBottom: 20,
	},
	autocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingRight: 65,
		},
	},
	filename: {
		backgroundColor: theme.palette.white,
		padding: '8px 14px',
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		'& svg': {
			marginRight: 10,
		},
		marginRight: 10,
		cursor: 'pointer',
	},
	revision: {
		backgroundColor: theme.palette.white,
		color: theme.palette.black,
		padding: '8px 14px',
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		'& svg': {
			marginRight: 10,
		},
		marginRight: 10,
		marginBottom: 10,
	},
	deleteIconButton: {
		backgroundColor: '#014164',
		padding: 3,
		'& path': {
			fill: theme.palette.white,
		},
	},
	deleteDraft: {
		marginLeft: theme.spacing(2),
		'& svg': {
			marginRight: 0,
		},
	},
	errorText: {
		color: theme.palette.error.main,
		fontWeight: 500,
	},
	overlay: {
		position: 'absolute',
		padding: '4rem',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		background: '#ffffffaa',
	},
	loader: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		marginTop: -20,
		marginLeft: -20,
	},
}));

const Form = ({ preview = true }) => {
	const [loading, setLoading] = useState(true);
	const [cardLoading, setCardLoading] = useState(false);
	const [responseError, setResponseError] = useState(0);
	const { register_id: registryId, id, version = null } = useParams();
	const classes = useStyles();
	const history = useHistory();
	const [isPreview] = useState(preview);

	let headerTitle = 'Podgląd karty';
	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			title: 'Lista rejestrów',
			to: Routes.Registries.List,
		},
		{ title: '', to: '' },
		{ title: headerTitle },
	]);
	const [headerTitleSuffix, setHeaderTitleSuffix] = useState('');
	const [cardData, setCardData] = useState({});
	const [saving] = useState(false);
	const [reload] = useState('');
	const [attachmentsKey, setAttachmentsKey] = useState(+new Date());
	const [schema, setSchema] = useState([]);
	const [schemaFields, setSchemaFields] = useState({});
	const [, setRegistryCards] = useState([]);
	const [params] = useState({
		registry_number: '',
		field: '',
		phrase: '',
		limit: 10,
	});

	const [cardNr, setCardNr] = useState({
		card_nr_nr: '',
		card_nr_nb: '',
		card_nr_year: '',
	});
	const [nextCardNr, setNextCardNr] = useState('');

	const [applicantType] = useState('list');
	const [representativeType] = useState('list');

	const [registry, setRegistry] = useState({});
	const [schemaAttachments, setSchemaAttachments] = useState({});
	const [revisions, setRevisions] = useState({});
	const [errors] = useState('');
	const [serverErrors] = useState('');
	const [, setValidators] = useState({});

	const loadRegistryCards = (params) => {
		if (!params?.field) return;

		API.registries.cards.search(registryId, params).then((res) => {
			if (res.data?.success) {
				setRegistryCards(res.data?.data);
			}
		});
	};

	const debouncedFetchData = useMemo(
		() => _.debounce((params) => loadRegistryCards(params), 500),
		[]
	);

	const processCardNr = (str) => {
		// FIXME
		const splittedCardNr = str.split('-');

		return {
			card_nr_nr: splittedCardNr[0],
			card_nr_nb: splittedCardNr[1].split('/')[0],
			card_nr_year: splittedCardNr[1].split('/')[1],
		};
	};

	useEffect(() => {
		debouncedFetchData(params);
	}, [params, debouncedFetchData]);

	useEffect(() => {
		setCardLoading(true);
	}, [version]);

	useEffect(() => {
		if (!registryId) return setLoading(false) && setResponseError(404);
		if (id) {
			API.registries.cards[version ? 'revision' : 'show'](id, version).then((res) => {
				let card = res.data.data;
				if (card.registry_id + '' !== registryId + '') {
					// show 404 for mismatched registry
					return setResponseError(404);
				}

				setRegistry(card.registry);
				setSchema(card.schema);
				setSchemaFields(card.fields);
				setSchemaAttachments(card.attachments);
				setRevisions(card.revisions);

				const cardData = card.data;
				if (cardData?.card_nr) {
					setCardNr(processCardNr(cardData.card_nr));
					setHeaderTitleSuffix(cardData.card_nr);

					setBreadcrumbs((prev) => {
						prev[2].title += ' ' + cardData.card_nr;
						return [...prev];
					});
				}

				setCardData(cardData);
				setAttachmentsKey(+new Date());
			}).catch(error => {
				setResponseError(error.response.status);
			}).finally(() => {
				setLoading(false);
				setCardLoading(false);
			});
		} else {
			API.registries.show(registryId).then(res => {
				const registry = res.data?.data;
				setRegistry(registry);
				setSchema(registry.schema);
				setSchemaFields(registry.fields);
				setSchemaAttachments(registry.attachments);
			}).catch(error => {
				setResponseError(error.response.status);
			}).finally(() => {
				setLoading(false);
				setCardLoading(false);
			});

			API.registries.cards.getNextNumber(registryId).then(res => {
				setNextCardNr(res.data?.data?.card_nb);
			});
		}
	}, [registryId, id, version, reload]);

	useEffect(() => {
		setBreadcrumbs((prev) => {
			prev[1].title = registry.name;
			prev[1].to = Routes.Registries.Cards.List(registryId);
			return [...prev];
		});
		setCardNr(prev => ({
			...prev,
			card_nr_nr: registry.number,
			card_nr_year: prev.card_nr_year || new Date().getFullYear(),
		}));
	}, [registry]);

	useEffect(() => {
		setValidators(() => {
			const validatorsData = {};
			Object.values(schemaFields).forEach((field) => {
				if (['applicant', 'representative'].includes(field.type)) {
					// do not validate in here?
				} else if (field?.required) {
					if (field?.max_length) {
						validatorsData[field.name] = new Validator(
							Required,
							LengthLimit(field.max_length)
						);
					} else {
						validatorsData[field.name] = new Validator(Required);
					}
				}
			});
			return validatorsData;
		});
	}, [schemaFields]);

	const handleChange = () => {};

	const handleChangeCardNr = () => {};

	const renderCardNrField = (field) => {
		const { name } = field;

		return (
			<Grid container spacing={1} className={classes.gutterBottom}>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Typography component="p">
							Numer karty (wniosku)
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<BaseInput
							required
							name={`${name}_nr`}
							value={cardNr[`${name}_nr`]}
							style={{ width: 60, textAlign: 'center' }}
							error={errors?.card_nr || serverErrors?.card_nr}
							disabled
						/>
						<Typography style={{ margin: '0 5px' }}>-</Typography>
						<BaseInput
							type="number"
							required
							name={`${name}_nb`}
							value={cardNr[`${name}_nb`]}
							InputProps={{ inputProps: { min: 0 } }}
							onChange={handleChangeCardNr}
							style={{ width: 60, textAlign: 'center' }}
							error={errors?.card_nr || serverErrors?.card_nr}
							disabled={isPreview}
							placeholder={nextCardNr}
						/>
						<Typography style={{ margin: '0 5px' }}>/</Typography>
						<BaseInput
							type="number"
							required
							name={`${name}_year`}
							value={cardNr[`${name}_year`]}
							InputProps={{ inputProps: { min: 0 } }}
							onChange={handleChangeCardNr}
							style={{ width: 60, textAlign: 'center' }}
							error={errors?.card_nr || serverErrors?.card_nr}
							disabled={isPreview}
						/>
					</Box>
				</Grid>
			</Grid>
		);
	};

	useEffect(() => {
		setCardData(prev => _.set({ ...prev }, 'applicant', null));
	}, [applicantType]);

	useEffect(() => {
		setCardData(prev => _.set({ ...prev }, 'representative', null));
	}, [representativeType]);

	const handleChangeApplicant = () => {};

	const handleChangeRepresentative = () => {};

	const renderError = message => {
		return (
			<Box marginBottom={1}>
				<Typography variant="bodyL" className={classes.errorText}>
					{message}
				</Typography>
			</Box>
		);
	};

	const renderApplicantField = () => {
		return (
			<>
				<Box>
					{typeof errors?.applicant === 'string' && (
						renderError(errors.applicant)
					)}

					<ApplicantForm
						applicant={cardData?.applicant}
						disabled={applicantType === 'list'}
						onChange={handleChangeApplicant}
						errors={applicantType !== 'list' ? serverErrors : {}}
					/>

					<Divider className={classes.headerMb}/>

					<Box>
						<Typography variant="h3" style={{ marginBottom: 20 }}>
							Przedstawiciel wnioskodawcy
						</Typography>

						<RepresentativeForm
							representative={cardData?.representative}
							onChange={handleChangeRepresentative}
							disabled={representativeType === 'list'}
							errors={representativeType !== 'list' ? serverErrors : {}}
						/>
					</Box>
				</Box>
			</>
		);
	};

	const renderField = name => {
		const field = schemaFields[name];
		const { type, label, required /*...rest*/ } = field;
		const value = cardData[name] ?? '';

		if (type === 'card_nr') {
			return <Box key={name}>{renderCardNrField(field)}</Box>;
		}

		if (type === 'applicant') {
			return <Box key={name}>{renderApplicantField(field)}</Box>;
		}

		if (type === 'registry_card') {
			return <RegistryCard
				key={name}
				name={name}
				value={value}
			/>;
		}

		if (type === 'select') {
			// const { options } = field;
			return (
				<Box key={name}>
					<FormInput
						multiline
						vertical
						title={label}
						name={name}
						value={value}
						onChange={handleChange}
						fullWidth
						required={required}
						error={errors?.[name] || serverErrors?.[name]}
						disabled={isPreview}
					/>
				</Box>
			);
		}

		if (type === 'date') {
			return (
				<Box key={name}>
					<DateInput
						vertical
						title={label}
						name={name}
						value={standarizeDate(value) || null}
						onChange={handleChange}
						fullWidth
						required={required}
						error={errors?.[name] || serverErrors?.[name]}
						disabled={isPreview}
					/>
				</Box>
			);
		}

		return (
			<Box key={name}>
				<FormInput
					multiline
					vertical
					title={label}
					name={name}
					value={value}
					onChange={handleChange}
					fullWidth
					required={required}
					error={errors?.[name] || serverErrors?.[name]}
					disabled={isPreview}
				/>
			</Box>
		);
	};

	const renderRow = (row) => {
		const rowSize = row.reduce((acc, col) => acc + col.size, 0);

		return (
			<Grid container spacing={2}>
				{row.map((col, index) => (
					<Grid item key={index} xs={col.size}>
						{col.fields.map((field) => renderField(field.name))}
					</Grid>
				))}
				{rowSize < 12 && <Grid item xs={12 - rowSize}/>}
			</Grid>
		);
	};

	const renderSection = (section) => {
		const { title, rows } = section;

		return (
			<Box key={title} className={classes.section}>
				<Typography variant="h3" style={{ marginBottom: 20 }}>
					{title}
				</Typography>
				{rows.map((row, index) => (
					<Box key={index}>{renderRow(row)}</Box>
				))}
			</Box>
		);
	};

	const goToList = () => history.push(Routes.Registries.Cards.List(registryId));

	const handleGeneratePDF = () => {
		window.print();
	};

	if (loading) {
		return <Progress status={true}/>;
	}
	if (hasErrorPage(responseError)) {
		return <ErrorPage code={responseError}/>;
	}

	return (
		<Box>
			<Breadcrumbs breadcrumbs={breadcrumbs}/>

			<Header title={headerTitle + (headerTitleSuffix ? ` ${headerTitleSuffix}` : '')} className={classes.headerMb}/>

			<Grid container style={{ position: 'relative' }}>
				<Grid item md={9} sm={12}>
					{(errors || serverErrors) && (
						<FormError
							className={classes.errorFormMr}
							errors={serverErrors}
						/>
					)}

					{Object.values(schema).map((section) =>
						section.type === 'attachments' ? (
							<Attachments
								key={attachmentsKey}
								schemaAttachments={schemaAttachments}
								cardId={id}
								cardData={cardData}
								setCardData={setCardData}
								setCardLoading={setCardLoading}
								isPreview={isPreview}
								classes={classes}
								version={version}
							/>
						) : renderSection(section)
					)}
				</Grid>
				<Grid item md={3} sm={12} id="publish-card">
					<Box className={classes.section} style={{ marginRight: 0 }}>
						<Box display="flex" justifyContent="space-between">
							<PrimaryButton onClick={goToList}>
								Lista kart
							</PrimaryButton>
						</Box>
					</Box>
					{id && Object.keys(revisions).length > 0 && (
						<Box className={classes.section} style={{ marginRight: 0 }}>
							<Typography variant="h3" style={{ marginBottom: 10 }}>
								{isPreview ? 'Historia zmian' : 'Wersje'}
							</Typography>
							{revisions?.map(revision => {
								const title = revision.current ? 'Aktualna' : revision.created_at;

								return ('' + revision.version === version || (!version && revision.status === 'published') ? (
									<Typography className={classes.revision}>
										<VisibleIcon/> {title}
									</Typography>
								) : (
									<Typography className={classes.revision}>
										<LinkIcon/> {title}
									</Typography>
								));
							})}
						</Box>
					)}

					<Box className={classes.section} style={{ marginRight: 0 }}>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h3">Drukuj</Typography>
							<PrimaryButton onClick={handleGeneratePDF} disabled={saving}>
								<SvgIcon color="secondary" style={{ marginRight: 10 }}>
									<Printer/>
								</SvgIcon>
								Generuj PDF
							</PrimaryButton>
						</Box>
					</Box>
				</Grid>
				{cardLoading && (
					<Box className={classes.overlay}>
						<Progress status={true} className={classes.loader}/>
					</Box>
				)}
			</Grid>
		</Box>
	);
};

export default Form;
