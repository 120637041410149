import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
	button: {
		borderRadius: 0,
		padding: '14px 20px',

		'& .MuiButton-label': {
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			fontSize: 13,
			lineHeight: 1.1,
		},
	},
	btnColor: {
		backgroundColor: '#103F62',
		color: '#fff',

		'&:hover': {
			backgroundColor: '#0F2F4E',
		},
	},
}));

const PrimaryButton = React.forwardRef((props, ref) => {
	const { children, className, variant = 'contained', ...rest } = props;
	const classes = useStyles();

	return (
		<Button
			ref={ref}
			variant={variant}
			disableElevation
			className={clsx(classes.button, className, {
				[classes.btnColor]: (variant === 'contained'),
			})}
			{...rest}
		>
			{children}
		</Button>
	);
});

PrimaryButton.displayName = 'PrimaryButton';
PrimaryButton.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default PrimaryButton;
