import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import {
	Box,
	TableCell,
	TableRow,
	makeStyles,
} from '@material-ui/core';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';

import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Header from 'components/Main/Header';
import Progress from 'components/Main/Progress';
import SchemaTable from 'components/Tables/SchemaTable';
import Select from 'components/Form/Select';
import Typography from 'components/Main/Typography';
import ErrorPage, { hasErrorPage } from 'pages/ErrorPage';
import { PreviewIcon } from 'assets/svg';

const useStyles = makeStyles(theme => ({
	yearSelector: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiGrid-container': {
			width: 100,
		},
	},
	number: {
		display: 'inline-block',
		fontSize: 'inherit',
		color: theme.palette.turquoise,
	},
}));

const Cards = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [responseError, setResponseError] = useState(0);
	const { id } = useParams();

	const [registry, setRegistry] = useState({});
	const [columns, setColumns] = useState([]);
	const [paginator, setPaginator] = useState(null);

	let defaultParams = {
		column: 'card_nr',
		direction: 'desc',
		limit: 20,
		page: 1,
		filters: {},
	};

	const history = useHistory();
	const location = useLocation();

	let paramsFromUrl = new URLSearchParams(history.location.search);
	try {
		if (paramsFromUrl.get('q')) {
			defaultParams = JSON.parse(decodeURIComponent(paramsFromUrl.get('q'))) ?? defaultParams;
		}
	} catch (Exception) {
		console.log('failed to decode query string!');
	}

	const [params, setParams] = useState(defaultParams);

	const [breadcrumbs, setBreadcrumbs] = useState([
		{ title: 'Lista rejestrów', to: Routes.Registries.List },
		{ title: '' },
	]);

	const [years, setYears] = useState([]);

	useEffect(() => loadData(), [params]);

	const handleYearChange = event => {
		const { name, value } = event.target;
		if (_.isEqual(params.filters[name], value)) return;

		setParams(prev => ({ ...prev, filters: { ...prev.filters, [name]: value } }));
	};

	const setFiltersToQueryString = (filters) => {
		const paramsToReplace = new URLSearchParams({q: encodeURIComponent(JSON.stringify(filters))});
		history.replace({ pathname: location.pathname, search: paramsToReplace.toString() });
	};

	useEffect(() => {
		setFiltersToQueryString(params);
	}, [params]);

	const loadData = () => {
		setPaginator(null);
		API.registries.cards.index(id, params).then(res => {
			setPaginator(res.data);
		});
	};

	useEffect(() => {
		if (!id) return setLoading(false) && setResponseError(404);

		API.registries.show(id).then(res => {
			const registry = res.data?.data;
			const fields = Object.values(registry.fields);

			setRegistry(registry);
			setColumns(fields.filter(col => col.list));

			setBreadcrumbs((prev) => {
				prev[1].title = registry.name;
				return [...prev];
			});
		}).catch(error => {
			setResponseError(error.response.status);
		}).finally(() => setLoading(false));

		API.registries.cards.years(id).then((res) => {
			if (res.data.success) {
				const yearList = res.data?.data?.map((year) => ({
					value: year,
					label: year,
				}));
				setYears(yearList);
			}
		});
	}, [id]);

	const renderRows = renderSchemaColumns => paginator && paginator.data.map((row, index) => (
		<TableRow key={index}>
			{renderSchemaColumns(row)}
			<TableCell>
				<Box display="flex">
					<Link
						to={Routes.Registries.Cards.Preview(row.registry_id, row.card_id)}
						aria-label={'Zobacz kartę ' + _.get(row, _.first(columns).name)}
					>
						<PreviewIcon fontSize="small" alt="Podgląd"/>
					</Link>
				</Box>
			</TableCell>
		</TableRow>
	));

	if (loading) {
		return <Progress status={true}/>;
	}
	if (hasErrorPage(responseError)) {
		return <ErrorPage code={responseError}/>;
	}

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<Header title={<><Typography component="span" className={classes.number}>[{registry.number}]</Typography> {registry.name}</>} />

			<Box display="flex" justifyContent="space-between" style={{ paddingBottom: 30 }}>
				<Box className={classes.yearSelector}>
					<Typography style={{ marginRight: 10 }}>
						Rok:
					</Typography>
					<Select
						vertical
						name="card_nr.year"
						value={params.filters['card_nr.year']}
						options={years}
						onChange={handleYearChange}
						gutterBottom={false}
						displayEmpty
						emptyLabel="dowolny"
						style={{ width: 104 }}
						aria-label={'Wyświetl karty dla ' + (params.filters['card_nr.year'] || 'dowolnego') + ' roku'}
					/>
				</Box>
			</Box>

			{!columns ? (
				<Progress status={true} />
			) : (
				<SchemaTable
					columns={columns}
					params={params}
					setParams={setParams}
					paginator={paginator}
					renderRows={renderRows}
					firstColumnLink={row => Routes.Registries.Cards.Edit(row.registry_id, row.card_id)}
					exportEndpoint={config => API.registries.cards.index(id, params, config)}
					exportFilename="Karty"
					initialFilters={params.filters}
				/>
			)}
		</>
	);
};

export default Cards;
