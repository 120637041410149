import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Grid, useTheme } from '@material-ui/core';

import Routes from 'router/Routes';

import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.lightGray,
		padding: 50
	},
	content: {
		paddingTop: 150,
		textAlign: 'center',
	},
	image: {
		marginTop: 50,
		display: 'inline-block',
		maxWidth: '100%',
		width: 560,
	},
	titleMb: {
		marginBottom: 30,
	},
	textWrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center'
	}
}));

export const hasErrorPage = code => {
	return [404, 500].includes(code);
};

const ErrorPage = ({ code = 404 }) => {
	const classes = useStyles();
	const theme = useTheme();

	let message = '';
	switch (code) {
		case 404:
			message = 'Strona nie została znaleziona. Skorzystaj z menu lub powróć na stronę startową.';
			break;
		case 500:
			message = 'Wystąpił nieoczekiwany błąd. Jeżeli będzie się powtarzał, prosimy powiadom nas o tym.';
			break;
		default:
			return '';
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={5}>
				<Grid item xs={12} sm={4}>
					<Box className={classes.textWrapper}>
						<Typography variant="h1" className={classes.titleMb}>
							Błąd {code}
						</Typography>

						<Box
							borderBottom={`6px solid ${theme.palette.orange}`}
							width={250}
							className={classes.titleMb}
						></Box>

						<Typography className={classes.titleMb}>
							{message}
						</Typography>

						<PrimaryButton component={Link} to={Routes.Home}>
							Strona główna
						</PrimaryButton>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8}>
					<img src={`${process.env.PUBLIC_URL}/images/${code}.png`} />
				</Grid>
			</Grid>
		</div>
	);
};

export default ErrorPage;
