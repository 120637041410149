const convertBytes = (bytes) => {
	if (!bytes) return;

	const units = ['bytes', 'KB', 'MB', 'GB'];
	let unitIndex = 0;
	while (bytes >= 1024 && unitIndex < units.length - 1) {
		bytes /= 1024;
		unitIndex++;
	}
	return `${bytes.toFixed(2)} ${units[unitIndex]}`;
};

export default convertBytes;
